import React from "react"
import "./App.css";
import { Main } from './components/views/main'

function App() {  
  return (   
      <div className="App">              
        <Main/>
      </div>
  );
}

export default App;
