import React, {Component} from "react"



export class Register extends Component {

    constructor(props){
        super(props)

        this.state={}
    }

    render(){
        return (
            <div>
                <label>Register...</label>
                <input 
                    onChange={
                        (e)=>{

                        }
                    }
                    onKeyUp={
                        (e)=>{

                        }
                    }

                />


            </div>
        )

    }


}


export class RegisterButton extends Component {

    constructor(props){
        super(props)

        this.state={}
    }

    render(){
        return (
            <div>               
                <button                    
                    onChange={
                        (e)=>{

                        }
                    }
                    onKeyUp={
                        (e)=>{

                        }
                    }

                >register</button>


            </div>
        )

    }


}